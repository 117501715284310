@import '../../assets/var.scss';

.Header {
  display: flex;
  align-items: center;
  padding: 24px;
  justify-content: space-between;
      
  div {
    display: flex;
    align-items: center;
  }
  
  .vert-line {
    margin: 0 24px;
    width: 1px;
    height: 28px;
    background-color: #fff;
  }
}

@media only screen and (max-width: 840px) {

  .Header {
    flex-direction: column;
  }
}