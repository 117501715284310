@import '../../assets/var.scss';

.ItemList {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;

  .list_event {
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-grow: 1;
    width: 100%;
  }

  .div_title {
    font-weight: 400;
    font-size: 38px;
  }

  .div_event {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    padding: 12px 24px;
    background: #363637;
    border-radius: 12px;
    gap: 12px;
  }

  .time {
    font-size: 32px;
    color: $secondary-color;
    font-weight: 600;
    display: inline-block;
    width: 132px;
    text-align: start;
    flex-shrink: 0;
  }

  .badge_place {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 4px 16px;
    background: #F081F1;
    border-radius: 24px;
    font-weight: 600;
    font-size: 24px;
    color: #131314;
  }

}

@media only screen and (max-width: 952px) {

  .ItemList {
  
    gap: 12px;
    flex-direction: column;

    .div_title {
      font-size: 28px;
    }

    .div_event {
      flex-direction: column;
      align-items: flex-start;
    }

    .time {
      font-size: 28px;
    }

    .badge_place {
      font-size: 18px;
    }

  }

}