@import './var.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

html,
body {
  min-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

body {
  font: 100% $font-main;
  color: $primary-color;
  background: $black;
  background-size: cover;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  display: flex;
  max-width: 1180px;
  height: 100%;
  width: 90%;
  padding: 0 32px 32px 32px;
  margin: auto;
  flex-direction: column;
}

@media only screen and (max-width: 840px) {
  .App {
    width: 100%;
  }
}

@media only screen and (max-width: 608px) {
  .App {
    width: 100%;
    padding: 0 0 32px 0;
  }
}