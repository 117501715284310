
$imagedir:'./images/';
$black: #131414;
$font-main: 'Montserrat', sans-serif;
$primary-color: rgb(255, 255, 255);
$secondary-color: #F081F1;

@mixin transition($x){
    transition: $x;
    -webkit-transition: $x;
    -moz-transition: $x;
    -ms-transition: $x;
    -o-transition: $x;
}