@import '../../assets/var.scss';

.Content {
  .Banner {
    height: 236px;
    width: 100%;
    background: url(../../assets/images/bg.jpg);
    border-radius: 16px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 32px;

    .Logo {
      height: 100%;
      width: 100%;
      background: url(../../assets/images/logo_df.svg);
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .Data {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 12px;
    background: #242425;
    border-radius: 16px;

    .date {
      font-size: 76px;
    }
  }

}

@media only screen and (max-width: 840px) {}

@media only screen and (max-width: 952px) {
  .Content {
    .Data {
      .date {
        font-size: 48px;
      }
    }
  }
}