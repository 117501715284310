@import '../../assets/var.scss';

.bttn {
  padding: 8px;
  border-bottom: 2px solid transparent;
  @include transition(border 0.2s);
  
  &:hover {
    border-bottom: 2px solid $secondary-color;
    @include transition(border 0.2s);
  }

  a {
    color: $primary-color;
    text-decoration: none;
    font-size: 26px;
  }

  span {
    font-size: 16px;
  }

  img {
    height: 36px;
    display: block;
  }
}